import React, { Fragment, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showToast } from "../../hooks/showToast";
import { axiosInstance } from "../../utils/commonInstance";
import { BackButtonModal } from "../../components/BackButtonModal";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";

const schema = yup.object({
  location_name: yup
    .string()
    .required("Please Enter Location")
    .max(150, "Length should be less than 150 characters").test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
      if (!value) return true; // Skip empty values (use required() to handle this)
      return value.trim() === value;
    }),
});

const LocationForm = ({ toggleFormFillrter, manualFetchData, isView, isEdit, previousData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleModalopen = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(()=>{
    if(isView || isEdit){
      setValue('location_name', previousData?.location_name)
      setValue('is_active', previousData?.location_is_active)
    }

  },[isEdit, isView, previousData, setValue])


  const onSubmit = async (data) => {

    const endPont = isEdit ?  `editlocation/${previousData?.id}` : 'addlocation'
    try {
      // const response = await axios.post(process.env.REACT_APP_BACKEND_API_URL + "login", {...data,'mode_of_login':'Web'})
      const response = await axiosInstance.post(`web/${endPont}`, {
        ...data,
        mode_of_login: "Web",
      });
      if (response?.data?.error) {
        showToast(response?.data?.message, "error");
        return false;
      }
      toggleFormFillrter()
      manualFetchData()
      showToast(response?.data?.message, "success");
      const dispatchData = response.data;
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };
  let result = checkObjectForNullUndefinedOrEmpty(watch());


  return (
    <>
      <section class="userlist-newuser content mobile">
        <div class="d-flex justify-content-end align-items-center mb-4 gap-3 flex-wrap">
          <h3 class="mb-0">
            <strong> {isEdit ? "Update" : isView ? "View" : "Add"} Location Details</strong>
          </h3>
          <button
            onClick={() =>
              result && !isView ? handleModalopen() : toggleFormFillrter()
            }
            class="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
          >
            Back
          </button>
        </div>

        <div class="user-details">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div class="card">
              <div class="row card-body justify-content-around py-md-5 py-4 align-items-center">
                <div class="col-lg-5 col-md-6">
                  <div class="form-group mb-3">
                    <label for="code" class="form-label">
                      Location<span class="text-danger">*</span>
                    </label>
                    <input
                      {...register("location_name")}
                      type="text"
                      disabled={isView}
                      class="form-control py-2"
                      id="code"
                      placeholder="Enter Location"
                    />
                    <p className="text-danger">
                      {errors.location_name?.message}
                    </p>
                  </div>
                </div>

                <div class="col-lg-5 col-md-6">
                  <div class="form-group mt-md-3 mb-3">
                    <div class="form-check">
                      <input
                        class="form-check-input mt-1"
                        type="checkbox"
                        value=""
                        disabled={isView}
                        id="flexCheckChecked"
                        {...register("is_active")}
                        defaultChecked={!isView || !isEdit}
                      />
                      <label
                        class="form-check-label mb-0"
                        for="flexCheckChecked"
                      >
                        IS ACTIVE
                      </label>
                      <p className="text-danger">{errors.is_active?.message}</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-5 col-md-6"></div>

                <div class="col-12 mt-lg-5 mt-4 d-sm-inline-flex justify-content-sm-center d-grid gap-3">
                  <button
                     disabled={isView}
                    class="btn btn-lg btn-primary px-5"
                   type="submit"
                  >
                    {isEdit ?  "UPDATE" : "SUBMIT"}
                  </button>
                  <button   disabled={isView || isEdit}
                    class="btn btn-lg btn-primary px-5 mx-xl-4"
                    type="button"
                    onClick={() => reset()}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <BackButtonModal isOpen={modal?.isOpen} handleClose={handleClose} toggleFormFillrter={toggleFormFillrter}/>
      </section>
    </>
  );
};

export default LocationForm;
