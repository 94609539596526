import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "../../App.css";
import * as yup from "yup";
import { axiosInstance } from "../../utils/commonInstance";
import { convertToBase64 } from "../../utils/commonFunction";
import * as moment from "moment";
import { showToast } from "../../hooks/showToast";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";
import { BackButtonModal } from "../../components/BackButtonModal";
import { Eye, EyeOff } from "feather-icons-react";
import Multiselect from "multiselect-react-dropdown";

const UserForm = ({
  toggleFormFillrter,
  setGlobalFilter,
  manualFetchData,
  isView,
  isEdit,
  previousData,
}) => {
  const [role, setRole] = useState("");
  const [roleOption, setRoleOption] = useState([]);
  const [companyOption, setCompanyOption] = useState([]);
  const [vendorOption, setVendorOption] = useState([]);
  const [getShiftOption, setGetShiftOption] = useState([]);
  const [designationOption, setDesignationOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [checkpointOption, setCheckpointOption] = useState([]);
  const [designationSalary, setDesignationSalary] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [modal, setModal] = useState({
    isOpen: false,
  });
  const [eyeStatus, setEyeStatus] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const multiselectRef = React.createRef();
  const [selectedCompanyOptions, setSelectedCompanyOptions] = useState([]);
  const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
  const [multiCompanyOption, setMultiCompanyOption] = useState([]);
  const [multiLocationOption, setMultiLocationOption] = useState([]);

  const userSchema = yup.object().shape({
    roles: yup.string().required("Role is required"),
    username: yup
      .string()
      .required("Please Enter Name")
      .matches(/^[A-Za-z\s]+$/, "Please enter name in alphabet only")
      .max(40, "Length should be less than 40 characters")
      .test(
        "no-spaces-at-ends",
        "No spaces allowed at the beginning or end",
        (value) => {
          if (!value) return true;
          return value.trim() === value;
        }
      ),
    email: isEdit
      ? yup.string().nullable()
      : yup
          .string()
          .email('Email Id should be in "xyz@gmail.com" format')
          .test(
            "no-spaces-at-ends",
            "No spaces allowed at the beginning or end",
            (value) => {
              if (!value) return true;
              return value.trim() === value;
            }
          ),
    mobile_no: yup
      .string()
      .required("Please enter Mobile No.")
      .matches(/^[6-9][0-9]{9}$/, "Mobile No must be numeric and valid"),
    password: isEdit
      ? yup.string().nullable()
      : yup
          .string()
          .required("Please enter Password")
          .min(8, "Password must be at least 8 characters")
          .max(15, "Password must be at most 15 characters")
          .matches(
            /.*[!@#$%^&*(),.?":{}|<>].*/,
            "Please enter one special character"
          )
          .test(
            "no-spaces",
            "Space is not allowed",
            (value) => !/\s/.test(value)
          ),
    date_of_joining: yup
      .date()
      .required("Please enter Date of joining")
      .typeError("Please enter Date of joining")
      .test("year-length", "Year must be a 4-digit number", (value) => {
        if (!value) return false;
        const year = value.getFullYear();
        return year.toString().length === 4;
      }),
    // company_id: yup.string().required("Please select Company"),
    // location_id: yup.string().required("Please select Location"),
    company_id: yup
      .mixed()
      .required("Please select Company")
      .test(
        "is-valid-company",
        "Please select Company",
        (value) => value !== "" && value !== undefined && value !== null
      ),
    location_id: yup
      .mixed()
      .required("Please select Location")
      .test(
        "is-valid-location",
        "Please select Location",
        (value) => value !== "" && value !== undefined && value !== null
      ),
    // company_id: yup.mixed().required("Please select Company"),
    // location_id: yup.mixed().required("Please select Location"),
    designation_id: yup.string().required("Please select Designation"),
    //   checkpoint_id: yup.string().required("Please select Checkpoint"),
    checkpoint_id: yup
      .array()
      .of(yup.string())
      .required("Please select Checkpoint")
      .min(1, "Please select Checkpoint"),
    vendor_id: yup.string().required("Please select Vendor"),
    salary: yup.string().required("Please enter salary"),
    shift_id: yup.string().required("Please select Shift"),
    adharcard_doc: isEdit
      ? yup.mixed().nullable()
      : yup.mixed().required("Please upload Aadhar Card in PDF format"),
    residence_doc: isEdit
      ? yup.mixed().nullable()
      : yup.mixed().required("Please upload Residence Address in PDF format"),
    police_verification_doc: isEdit
      ? yup.mixed().nullable()
      : yup
          .mixed()
          .required(
            "Please upload Police verification number file in PDF format"
          ),
    gunlicence_doc: yup.mixed().nullable(),
  });

  const adminSchema = yup.object().shape({
    roles: yup.string().required("Role is required"),
    username: yup
      .string()
      .required("Please Enter Name")
      .matches(/^[A-Za-z\s]+$/, "Please enter name in alphabet only")
      .max(40, "Length should be less than 40 characters")
      .test(
        "no-spaces-at-ends",
        "No spaces allowed at the beginning or end",
        (value) => {
          if (!value) return true;
          return value.trim() === value;
        }
      ),
    email: yup
      .string()
      .email('Email Id should be in "xyz@gmail.com" format')
      .test(
        "no-spaces-at-ends",
        "No spaces allowed at the beginning or end",
        (value) => {
          if (!value) return true;
          return value.trim() === value;
        }
      ),
    mobile_no: yup
      .string()
      .required("Please enter Mobile No.")
      .matches(/^[6-9][0-9]{9}$/, "Mobile No must be numeric and valid"),
    password: isEdit
      ? yup.string().nullable()
      : yup
          .string()
          .required("Please enter Password")
          .min(8, "Password must be at least 8 characters")
          .max(15, "Password must be at most 15 characters")
          .matches(
            /.*[!@#$%^&*(),.?":{}|<>].*/,
            "Please enter one special character"
          )
          .test(
            "no-spaces",
            "Space is not allowed",
            (value) => !/\s/.test(value)
          ),
    date_of_joining: yup
      .date()
      .required("Please enter Date of joining")
      .typeError("Please enter Date of joining")
      .test("year-length", "Year must be a 4-digit number", (value) => {
        if (!value) return false;
        const year = value.getFullYear();
        return year.toString().length === 4;
      }),
    // company_id: yup.string().required("Please select Company"),
    // location_id: yup.string().required("Please select Location"),
    // Validation for multi-company selection

    multi_company_id: yup
      .array()
      .min(1, "Please select Company")
      .required("Please select Company"),

    // Validation for multi-location selection
    multi_location_id: yup
      .array()
      .min(1, "Please select Location")
      .required("Please select Location"),
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm({
    resolver: yupResolver(role !== "Mobile User" ? adminSchema : userSchema),
    defaultValues: previousData || {},
  });

  const onSubmit = async (data) => {
    if (!isEdit && role === "Mobile User") {
      if (!data?.adharcard_doc?.length) {
        setError("adharcard_doc", {
          type: "manual",
          message: "Please upload Aadhar Card in PDF format",
        });
        return;
      }
      if (!data?.residence_doc?.length) {
        setError("residence_doc", {
          type: "manual",
          message: "Please upload Residence Address in PDF format",
        });
        return;
      }
      if (!data?.police_verification_doc?.length) {
        setError("police_verification_doc", {
          type: "manual",
          message: "Please upload Police verification number file in PDF format",
        });
        return;
      }
    }

    const params = {
      roles: data?.roles,
      is_active: data?.is_active,
      username: data?.username,
      email: data?.email,
      mobile_no: data?.mobile_no,
      password: data?.password,
      date_of_joining: data?.date_of_joining
        ? moment(data?.date_of_joining).format("DD/MM/YYYY")
        : //   moment(data?.date_of_joining).format("MM/DD/YYYY")
          null,
      //   company_id: data?.company_id,
      //   location_id: data?.location_id,
      company_id:
        role === "Mobile User" ? data?.company_id : data?.multi_company_id,
      location_id:
        role === "Mobile User" ? data?.location_id : data?.multi_location_id,
      designation_id: data?.designation_id ? data?.designation_id : null,
      checkpoint_id: data?.checkpoint_id ? data?.checkpoint_id : null,
      vendor_id: data?.vendor_id ? data?.vendor_id : null,
      salary: data?.salary ? data?.salary : null,
      shift_id: data?.shift_id ? data?.shift_id : null,
      adharcard_doc:
        role == "Mobile User" && data?.adharcard_doc
          ? await convertToBase64(data?.adharcard_doc[0])
          : null,
      residence_doc:
        role == "Mobile User" && data?.residence_doc
          ? await convertToBase64(data?.residence_doc[0])
          : null,
      police_verification_doc:
        role == "Mobile User" && data?.police_verification_doc
          ? await convertToBase64(data?.police_verification_doc[0])
          : null,
      gunlicence_doc:
        role == "Mobile User" && data?.gunlicence_doc
          ? await convertToBase64(data?.gunlicence_doc[0])
          : null,
    };
    // console.log(selectedCompanyOptions);
    // console.log(selectedLocationOptions);
    // console.log("ttttttttttt", params);
    debugger;
    const endPont = isEdit ? `edituser/${previousData?.id}` : "adduser";
    try {
      const response = await axiosInstance.post(`web/${endPont}`, {
        ...params,
        mode_of_login: "Web",
      });

      console.log(response);

      //   if (response?.data?.statusCode !== 200) {
      if (response?.data?.error) {
        showToast(response?.data?.message, "error");
        return false;
      }
      toggleFormFillrter(false);
      manualFetchData();
      showToast(response?.data?.message, "success");
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
    clearErrors();
  };

  useEffect(() => {
    async function fetchData() {
    //   const response = await axiosInstance.get(`web/companydata`);
      const response = await axiosInstance.get(`web/companydatauserform`);
    //   const roleResponse = await axiosInstance.get(`web/roles`);
      const roleResponse = await axiosInstance.get(`web/roledatauserslist`);
    //   const vendorResponse = await axiosInstance.get(`web/vendor`);
      const vendorResponse = await axiosInstance.get(`web/vendordata`);
      //   const getShiftResponse = await axiosInstance.get(`web/getshift`);
      //   setGetShiftOption(getShiftResponse?.data?.data);
      setVendorOption(vendorResponse?.data?.data);
      setRoleOption(roleResponse?.data?.data);
      setCompanyOption(response?.data?.data);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchCompany() {
    //   const response = await axiosInstance.get(`web/companysalaryfilter`);
      const response = await axiosInstance.get(`web/companymultiselect`);
      if (!response?.data?.error) {
        const formattedOptions = response?.data?.data?.map((ele) => ({
          id: ele?.id,
          name: ele?.company_name,
        }));
        setMultiCompanyOption(formattedOptions);
      } else {
        setMultiCompanyOption([]);
        // showToast(response?.data?.message, "error");
      }
    }
    fetchCompany();
  }, []);

  const selectedMultiCompanyId = watch("multi_company_id");
  useEffect(() => {
    if (selectedMultiCompanyId) {
      //   if (selectedMultiCompanyId?.length === 0) {
      //     setSelectedLocationOptions([]);
      //   }

      async function fetchLocations() {
        const params = {
          company_id: selectedMultiCompanyId,
        };
        console.log(params);
        try {
          const response = await axiosInstance.post(
            // `web/locationsalaryfilter`,
            `web/locationsalaryuserform`,
            {
              ...params,
              mode_of_login: "Web",
            }
          );
          if (response?.data?.statusCode !== 200) {
            return false;
          }
          const formattedOptions = response?.data?.data?.map((ele) => ({
            id: ele?.id,
            name: ele?.name,
          }));
          setMultiLocationOption(formattedOptions);
        } catch (error) {
          showToast(error?.data?.message, "error");
        }
      }
      fetchLocations();
    }
  }, [selectedMultiCompanyId]);

  const selectedCompanyId = watch("company_id");
  const selectedLocationId = watch("location_id");
  const selectedDesignationId = watch("designation_id");
  useEffect(() => {
    if (selectedCompanyId) {
      async function fetchLocations() {
        const response = await axiosInstance.get(
          `web/locationdata/${selectedCompanyId}`
        );
        if (!response?.data?.error) {
          setLocationOption(response?.data?.data);
        } else {
          setLocationOption(response?.data?.data);

          //   showToast(response?.data?.message, "error");
        }
      }
      fetchLocations();
    }

    if (selectedLocationId) {
      async function fetchDesignations() {
        const response = await axiosInstance.get(
          `web/designationdata?companyId=${selectedCompanyId}&locationId=${selectedLocationId}`
        );
        if (!response?.data?.error) {
          setDesignationOption(response?.data?.data);
        } else {
          setDesignationOption(response?.data?.data);

          //   showToast(response?.data?.message, "error");
        }
      }
      fetchDesignations();
    }

    if (selectedCompanyId && selectedLocationId) {
      async function fetchCheckpoints() {
        const response = await axiosInstance.get(
          `web/checkpointdata?companyId=${selectedCompanyId}&locationId=${selectedLocationId}`
        );
        if (!response?.data?.error) {
          setCheckpointOption(response?.data?.data);
        } else {
          setCheckpointOption(response?.data?.data);
          //   showToast(response?.data?.message, "error");
        }
      }
      fetchCheckpoints();
    }

    if (selectedCompanyId && selectedLocationId) {
      async function fetchShifts() {
        const response = await axiosInstance.get(
          `web/shiftdata?companyId=${selectedCompanyId}&locationId=${selectedLocationId}`
        );
        if (!response?.data?.error) {
          setGetShiftOption(response?.data?.data);
        } else {
          setGetShiftOption(response?.data?.data);
          //   showToast(response?.data?.message, "error");
        }
      }
      fetchShifts();
    }
    if (selectedDesignationId) {
      async function fetchDesignationSalary() {
        const response = await axiosInstance.get(
          `web/designationsalary/${selectedDesignationId}`
        );
        if (!response?.data?.error) {
          role == "Mobile User"
            ? setDesignationSalary(response?.data?.data.designation_salary)
            : setDesignationSalary("");
          // setValue("salary", response?.data?.data.designation_salary || "");
          // document.getElementById("salary").focus();
        } else {
          setDesignationSalary(response?.data?.data.designation_salary);
          //   setValue("salary", "");
          // showToast(response?.data?.message, "error");
        }
      }
      fetchDesignationSalary();
    }
  }, [selectedCompanyId, selectedLocationId, selectedDesignationId, role]);

  const handleModalopen = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  const formattedOptions = checkpointOption?.map((ele) => ({
    id: ele?.id,
    name: ele?.checkpoint_name,
  }));

  const handleSelect = (selectedList, selectedItem) => {
    setSelectedOptions(selectedList);
    setValue(
      "checkpoint_id",
      selectedList.map((item) => item.id)
    );
  };

  const handleRemove = (selectedList, removedItem) => {
    setSelectedOptions(selectedList);
    setValue(
      "checkpoint_id",
      selectedList.map((item) => item.id)
    );
  };

  const handleComLocSelect = (
    selectedList,
    selectedItem,
    optionList,
    setSelectedOptions,
    setFieldValue,
    fieldName
  ) => {
    if (selectedItem?.id === "All") {
      const ids = optionList.map((option) => option.id);
      setSelectedOptions(optionList);
      setFieldValue(fieldName, ids);
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        const ids = newList.map((option) => option.id);
        setSelectedOptions(newList);
        setFieldValue(fieldName, ids);
      } else {
        if (optionList?.length - 1 === selectedList?.length) {
          const ids = optionList.map((option) => option.id);
          setSelectedOptions(optionList);
          setFieldValue(fieldName, ids);
        } else {
          const ids = selectedList.map((option) => option.id);
          setSelectedOptions(selectedList);
          setFieldValue(fieldName, ids);
        }
      }
    }
  };

  const handleComLocRemove = (
    selectedList,
    removedItem,
    setSelectedOptions,
    setFieldValue,
    fieldName,
    resetLocations
  ) => {
    if (removedItem?.id === "All") {
      setSelectedOptions([]);
      setFieldValue(fieldName, []);
      resetLocations();
    } else {
      const allSelected = selectedList.some((item) => item.id === "All");
      if (allSelected) {
        const newList = selectedList.filter((item) => item.id !== "All");
        const ids = newList.map((option) => option.id);
        setSelectedOptions(newList);
        setFieldValue(fieldName, ids);
        resetLocations();
      } else {
        const ids = selectedList.map((option) => option.id);
        setSelectedOptions(selectedList);
        setFieldValue(fieldName, ids);
        // if (!selectedList.length) {
        if (!selectedList.length || selectedList.length) {
          resetLocations();
        }
      }
    }
  };

  const resetSelectedLocations = () => {
    setSelectedLocationOptions([]);
    setValue("multi_location_id", []);
  };

  useEffect(() => {
    async function setFiles() {
      if (isView || isEdit) {
        setRole(previousData?.role_name);
        setValue("roles", previousData?.role_name);
        setValue("is_active", previousData?.is_active);
        setValue("username", previousData?.username);
        setValue("email", previousData?.email);
        setValue("mobile_no", previousData?.mobile_no);
        if (previousData?.date_of_joining) {
          // Parse the incoming date string and format it to "MM-DD-YYYY"
          const parsedDate = moment(
            previousData.date_of_joining,
            "DD-MM-YYYY"
          ).format("YYYY-MM-DD");
          setValue("date_of_joining", parsedDate);
        }

        const companyIds = previousData?.company.map((c) => c.id);
        setValue("multi_company_id", companyIds);
        const locationIds = previousData?.location.map((l) => l.id);
        setValue("multi_location_id", locationIds);

        const formattedCompanies = previousData?.company?.map((company) => ({
          id: company.id,
          name: company.company_name,
        }));
        setSelectedCompanyOptions(formattedCompanies);

        const formattedLocations = previousData?.location?.map((location) => ({
          id: location.id,
          name: `${location.company_name} - ${location.location_name}`,
        }));
        setSelectedLocationOptions(formattedLocations);

        setValue("company_id", previousData?.company_id);
        setValue("location_id", previousData?.location_id);
        setValue("designation_id", previousData?.designation_id);
        setSelectedOptions(previousData?.checkpoints);
        setValue("checkpoint_id", previousData?.checkpoints_id);
        setValue("vendor_id", previousData?.vendor_id);
        setValue("salary", previousData?.salary);
        setValue("shift_id", previousData?.shift_id);
      }
    }

    setFiles();
  }, [isEdit, isView, previousData]);

  let result = checkObjectForNullUndefinedOrEmpty(watch());

  const watchRoles = watch("roles");
  const watchIsActive = watch("is_active");
  const watchUsername = watch("username");
  const watchEmail = watch("email");
  const watchMobileNo = watch("mobile_no");
  const watchPassword = watch("password");
  const watchDateOfJoining = watch("date_of_joining");
  const watchCompanyId = watch("company_id");
  const watchLocationId = watch("location_id");
  const watchDesignationId = watch("designation_id");
  const watchCheckpointId = watch("checkpoint_id");
  const watchVendorId = watch("vendor_id");
  const watchSalary = watch("salary");
  const watchShiftId = watch("shift_id");
  const watchAdharCardDoc = watch("adharcard_doc");
  const watchResidenceDoc = watch("residence_doc");
  const watchPoliceVerificationDoc = watch("police_verification_doc");
  const watchGunLicenceDoc = watch("gunlicence_doc");

  return (
    <>
      <section className="userlist-newuser content mobile">
        <div className="d-flex justify-content-end align-items-center mb-4 gap-3 flex-wrap">
          <h3 className="mb-0">
            <strong>
              {isEdit ? "Update" : isView ? "View" : "Add"} User Details
            </strong>
          </h3>
          {isEdit ? (
            <button
              onClick={() => {
                const isFormEdited =
                  role !== "Mobile User"
                    ? watchRoles !== previousData?.role_name ||
                      watchIsActive !== previousData?.is_active ||
                      watchUsername !== previousData?.username ||
                      watchEmail !== previousData?.email ||
                      watchMobileNo !== previousData?.mobile_no ||
                      watchPassword != null ||
                      watchCompanyId !== previousData?.company_id ||
                      watchLocationId !== previousData?.location_id
                    : watchIsActive !== previousData?.is_active ||
                      watchUsername !== previousData?.username ||
                      watchEmail !== previousData?.email ||
                      watchMobileNo !== previousData?.mobile_no ||
                      watchPassword != null ||
                      watchCompanyId !== previousData?.company_id ||
                      watchLocationId !== previousData?.location_id ||
                      watchDesignationId !== previousData?.designation_id ||
                      selectedOptions !== previousData?.checkpoints ||
                      watchVendorId !== previousData?.vendor_id ||
                      //   watchSalary !== previousData?.salary ||
                      watchShiftId !== previousData?.shift_id ||
                      watchAdharCardDoc != null ||
                      watchResidenceDoc != null ||
                      watchPoliceVerificationDoc != null ||
                      watchGunLicenceDoc != null;

                if (isFormEdited) {
                  handleModalopen();
                } else {
                  toggleFormFillrter();
                }
                setGlobalFilter({ selectFillter: "", search: "" });
              }}
              className="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
            >
              Back
            </button>
          ) : (
            <button
              onClick={() => {
                result && role && !isView
                  ? handleModalopen()
                  : toggleFormFillrter();
                setGlobalFilter({ selectFillter: "", search: "" });
              }}
              className="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
            >
              Back
            </button>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div className="user-details">
            <div className="card">
              <div className="row card-body justify-content-around py-md-5 py-4 align-items-center">
                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="roles" className="form-label">
                      Select Role<span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="roles"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={
                            isView || (isEdit && role === "Mobile User")
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            setRole(e.target.value);
                            // setValue("date_of_joining", "");
                            setValue("company_id", "");
                            setValue("location_id", "");
                            setValue("designation_id", "");
                            setValue("checkpoint_id", []);
                            setSelectedOptions([]);
                            setValue("vendor_id", "");
                            setValue("shift_id", "");
                            setValue("salary", "");
                            setDesignationSalary("");
                          }}
                        >
                          <option value="" disabled>
                            Role
                          </option>
                          {roleOption?.map((ele) => (
                            <option value={ele.name}>{ele.name}</option>
                          ))}
                        </select>
                      )}
                    />
                  </div>
                  {!role ? (
                    <p className="text-danger">Please Select Role</p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mt-md-3 mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        id="is_active"
                        value=""
                        defaultChecked
                        {...register("is_active")}
                        disabled={isView}
                      />
                      <label
                        className="form-check-label mb-0"
                        htmlFor="is_active"
                      >
                        IS ACTIVE
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="username" className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      maxLength={50}
                      id="username"
                      placeholder="Enter Name"
                      {...register("username")}
                      autoComplete="off"
                      disabled={!role || isView}
                    />
                    {errors.username && (
                      <p className="text-danger">{errors.username.message}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control py-2"
                      maxLength={50}
                      id="email"
                      placeholder="Enter Email"
                      {...register("email")}
                      autoComplete="off"
                      disabled={!role || isView}
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="mobile_no" className="form-label">
                      Mobile No<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      maxLength={10}
                      id="mobile_no"
                      placeholder="Enter Mobile No."
                      {...register("mobile_no")}
                      autoComplete="off"
                      disabled={!role || isView}
                    />
                    {errors.mobile_no && (
                      <p className="text-danger">{errors.mobile_no.message}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="password" className="form-label">
                      Create Password
                      {!isEdit ? <span className="text-danger">*</span> : ""}
                    </label>

                    <div className="position-relative">
                      <input
                        type={eyeStatus ? "text" : "password"}
                        className="form-control py-2"
                        id="password"
                        placeholder="Enter Password"
                        {...register("password")}
                        autoComplete="off"
                        disabled={!role || isView}
                      />
                      <button
                        type="button"
                        className="passforget_user border-0 bg-transparent"
                        onClick={() => setEyeStatus(!eyeStatus)}
                        disabled={!role || isView}
                      >
                        {eyeStatus ? <Eye /> : <EyeOff />}
                      </button>
                      {errors.password && (
                        <p className="text-danger">{errors.password.message}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="date_of_joining" className="form-label">
                      Date of Joining<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      id="date_of_joining"
                      name="date_of_joining"
                      className="form-control text-uppercase py-2"
                      {...register("date_of_joining")}
                      //   disabled={
                      //     !role ||
                      //     role != "Mobile User" ||
                      //     isView ||
                      //     (isEdit && role === "Mobile User")
                      //   }
                      disabled={
                        !role || isView || (isEdit && role === "Mobile User")
                      }
                    />
                    {/* {role == "Mobile User"
                      ? errors.date_of_joining && (
                          <p className="text-danger">
                            {errors.date_of_joining.message}
                          </p>
                        )
                      : ""} */}
                    {errors.date_of_joining && (
                      <p className="text-danger">
                        {errors.date_of_joining.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="multi_company_id" className="form-label">
                      Select Company<span className="text-danger">*</span>
                    </label>
                    {role !== "Mobile User" ? (
                      <Multiselect
                        ref={multiselectRef}
                        options={multiCompanyOption}
                        selectedValues={selectedCompanyOptions}
                        displayValue="name"
                        placeholder={"Company"}
                        defaultValue=""
                        disable={!role || isView}
                        onSelect={(selectedList, selectedItem) =>
                          handleComLocSelect(
                            selectedList,
                            selectedItem,
                            multiCompanyOption,
                            setSelectedCompanyOptions,
                            setValue,
                            "multi_company_id"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          //   handleComLocRemove(
                          //     selectedList,
                          //     removedItem,
                          //     setSelectedCompanyOptions,
                          //     setValue,
                          //     "multi_company_id"
                          //   )
                          handleComLocRemove(
                            selectedList,
                            removedItem,
                            setSelectedCompanyOptions,
                            setValue,
                            "multi_company_id",
                            resetSelectedLocations
                          )
                        }
                        avoidHighlightFirstOption={true}
                        hidePlaceholder={true}
                        showCheckbox={true}
                        name="multi_company_id"
                        id="multi_company_id"
                        {...register("multi_company_id")}
                        style={{
                          searchBox: {
                            height:
                              selectedCompanyOptions?.length >= 3
                                ? "100px"
                                : "40px",
                            overflowY:
                              selectedCompanyOptions?.length >= 3
                                ? "scroll"
                                : "auto",
                          },
                        }}
                      />
                    ) : (
                      <Controller
                        name="company_id"
                        control={control}
                        render={({ field }) => (
                          <select
                            className="form-select py-2 w-100"
                            {...field}
                            defaultValue=""
                            disabled={!role || isView}
                            onChange={(e) => {
                              field.onChange(e);
                              setValue("location_id", "");
                              setValue("designation_id", "");
                              setValue("checkpoint_id", []);
                              setSelectedOptions([]);
                              setValue("vendor_id", "");
                              setValue("shift_id", "");
                              setDesignationSalary("");
                            }}
                          >
                            <option value="" disabled>
                              Company
                            </option>
                            {companyOption?.map((ele) => (
                              <option value={ele?.id}>
                                {ele?.company_name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                    )}
                    {errors.multi_company_id && (
                      <p className="text-danger">
                        {errors.multi_company_id.message}
                      </p>
                    )}
                    {errors.company_id && (
                      <p className="text-danger">{errors.company_id.message}</p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="multi_location_id" className="form-label">
                      Select Location<span className="text-danger">*</span>
                    </label>
                    {role !== "Mobile User" ? (
                      <Multiselect
                        ref={multiselectRef}
                        options={multiLocationOption}
                        selectedValues={selectedLocationOptions}
                        displayValue="name"
                        placeholder={"Location"}
                        defaultValue=""
                        disable={!role || isView}
                        onSelect={(selectedList, selectedItem) =>
                          handleComLocSelect(
                            selectedList,
                            selectedItem,
                            multiLocationOption,
                            setSelectedLocationOptions,
                            setValue,
                            "multi_location_id"
                          )
                        }
                        onRemove={(selectedList, removedItem) =>
                          handleComLocRemove(
                            selectedList,
                            removedItem,
                            setSelectedLocationOptions,
                            setValue,
                            "multi_location_id"
                          )
                        }
                        avoidHighlightFirstOption={true}
                        hidePlaceholder={true}
                        showCheckbox={true}
                        name="multi_location_id"
                        id="multi_location_id"
                        {...register("multi_location_id")}
                        style={{
                          searchBox: {
                            height:
                              selectedLocationOptions?.length >= 3
                                ? "100px"
                                : "40px",
                            overflowY:
                              selectedLocationOptions?.length >= 3
                                ? "scroll"
                                : "auto",
                          },
                        }}
                      />
                    ) : (
                      <Controller
                        name="location_id"
                        control={control}
                        render={({ field }) => (
                          <select
                            className="form-select py-2 w-100"
                            {...field}
                            defaultValue=""
                            disabled={!role || isView}
                            onChange={(e) => {
                              field.onChange(e);
                              setValue("designation_id", "");
                              setValue("checkpoint_id", []);
                              setSelectedOptions([]);
                              setValue("vendor_id", "");
                              setValue("shift_id", "");
                              setDesignationSalary("");
                            }}
                          >
                            <option value="" disabled>
                              Location
                            </option>
                            {locationOption?.map((ele) => (
                              <option value={ele?.id}>
                                {ele?.location_name}
                              </option>
                            ))}
                          </select>
                        )}
                      />
                    )}
                    {errors.multi_location_id && (
                      <p className="text-danger">
                        {errors.multi_location_id.message}
                      </p>
                    )}
                    {errors.location_id && (
                      <p className="text-danger">
                        {errors.location_id.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="designation_id" className="form-label">
                      Select Designation<span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="designation_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={!role || role != "Mobile User" || isView}
                        >
                          <option value="" disabled>
                            Designation
                          </option>
                          {designationOption?.map((ele) => (
                            <option value={ele?.id}>
                              {ele?.designation_name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {role == "Mobile User"
                      ? errors.designation_id && (
                          <p className="text-danger">
                            {errors.designation_id.message}
                          </p>
                        )
                      : ""}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="checkpoint_id" className="form-label">
                      Assigned Checkpoint<span className="text-danger">*</span>
                    </label>

                    <Multiselect
                      options={formattedOptions}
                      selectedValues={selectedOptions}
                      onSelect={handleSelect}
                      onRemove={handleRemove}
                      displayValue="name"
                      placeholder={
                        selectedOptions.length === 0 ? "Checkpoint" : ""
                      }
                      {...register("checkpoint_id")}
                      defaultValue=""
                      disable={!role || role !== "Mobile User" || isView}
                    />

                    {role == "Mobile User" && selectedOptions.length === 0
                      ? errors.checkpoint_id && (
                          <p className="text-danger">
                            {errors.checkpoint_id.message}
                          </p>
                        )
                      : ""}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="vendor_id" className="form-label">
                      Select Vendor Name<span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="vendor_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={!role || role != "Mobile User" || isView}
                        >
                          <option value="" disabled>
                            Vendor Name
                          </option>
                          {vendorOption?.map((ele) => (
                            <option value={ele?.id}>{ele.vendor_name}</option>
                          ))}
                        </select>
                      )}
                    />
                    {role == "Mobile User"
                      ? errors.vendor_id && (
                          <p className="text-danger">
                            {errors.vendor_id.message}
                          </p>
                        )
                      : ""}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="salary" className="form-label">
                      Salary<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="salary"
                      placeholder="Enter Salary"
                      {...register("salary")}
                      value={designationSalary}
                      //   onChange={handleInputChange}
                      disabled={!role || role != "Mobile User" || isView}
                    />
                    {/* {role == "Mobile User"
                      ? errors.salary && (
                          <p className="text-danger">{errors.salary.message}</p>
                        )
                      : ""} */}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="shift_id" className="form-label">
                      Select Shift<span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="shift_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={!role || role != "Mobile User" || isView}
                        >
                          <option value="" disabled>
                            Shift
                          </option>
                          {getShiftOption?.map((ele) => (
                            <option
                              value={ele?.id}
                            >{`${ele.shift_name}  - ${ele?.start_time} to ${ele?.end_time}`}</option>
                          ))}
                        </select>
                      )}
                    />
                    {role == "Mobile User"
                      ? errors.shift_id && (
                          <p className="text-danger">
                            {errors.shift_id.message}
                          </p>
                        )
                      : ""}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="adharcard_doc" className="form-label">
                      Upload Aadhar Card
                      {!isEdit ? <span className="text-danger">*</span> : ""}
                    </label>
                    <input
                      className="form-control py-2 text-capitalize"
                      type="file"
                      accept=".pdf"
                      id="adharcard_doc"
                      {...register("adharcard_doc")}
                      disabled={!role || role != "Mobile User" || isView}
                    />
                    {previousData?.adharcard_file_url && (
                      <a
                        href={previousData?.adharcard_file_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Uploaded Aadhar Card
                      </a>
                    )}
                    {/* {role == "Mobile User"
                      ? errors?.adharcard_doc && (
                          <p className="text-danger">
                            {errors?.adharcard_doc?.message}
                          </p>
                        )
                      : ""} */}
                    {role === "Mobile User" && errors?.adharcard_doc && (
                      <p className="text-danger">
                        {errors?.adharcard_doc?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="residence_doc" className="form-label">
                      Upload Residence Address
                      {!isEdit ? <span className="text-danger">*</span> : ""}
                    </label>
                    <input
                      className="form-control py-2 text-capitalize"
                      type="file"
                      accept=".pdf"
                      id="residence_doc"
                      {...register("residence_doc")}
                      disabled={!role || role != "Mobile User" || isView}
                    />
                    {previousData?.residence_image_url && (
                      <a
                        href={previousData?.residence_image_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Uploaded Residence Address
                      </a>
                    )}
                    {role == "Mobile User"
                      ? errors.residence_doc && (
                          <p className="text-danger">
                            {errors?.residence_doc?.message}
                          </p>
                        )
                      : ""}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label
                      htmlFor="police_verification_doc"
                      className="form-label"
                    >
                      Upload Police VC No.
                      {!isEdit ? <span className="text-danger">*</span> : ""}
                    </label>
                    <input
                      className="form-control py-2 text-capitalize"
                      type="file"
                      accept=".pdf"
                      id="police_verification_doc"
                      {...register("police_verification_doc")}
                      disabled={!role || role != "Mobile User" || isView}
                    />
                    {previousData?.police_verification_image_url && (
                      <a
                        href={previousData?.police_verification_image_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Uploaded Police VC No.
                      </a>
                    )}
                    {role == "Mobile User"
                      ? errors.police_verification_doc && (
                          <p className="text-danger">
                            {errors.police_verification_doc.message}
                          </p>
                        )
                      : ""}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="gunlicence_doc" className="form-label">
                      Upload Gun License (if any)
                    </label>
                    <input
                      className="form-control py-2 text-capitalize"
                      type="file"
                      accept=".pdf"
                      id="gunlicence_doc"
                      {...register("gunlicence_doc")}
                      disabled={!role || role != "Mobile User" || isView}
                    />
                    {previousData?.gunlicence_image_url && (
                      <a
                        href={previousData?.gunlicence_image_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Uploaded Gun License
                      </a>
                    )}
                  </div>
                </div>

                <div className="col-12 text-center mt-lg-5 mt-4">
                  <button
                    disabled={!role || isView}
                    className="btn btn-lg btn-primary px-5"
                    type="submit"
                  >
                    {isEdit ? "UPDATE" : "SUBMIT"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <BackButtonModal
          isOpen={modal?.isOpen}
          handleClose={handleClose}
          toggleFormFillrter={toggleFormFillrter}
        />
      </section>
    </>
  );
};

export default UserForm;
