import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";
import { BackButtonModal } from "../../components/BackButtonModal";

const schema = yup.object().shape({
  designation: yup
    .string()
    .required("Please enter Designation")
    .test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
      if (!value) return true; // Skip empty values (use required() to handle this)
      return value.trim() === value;
    }),
  location_id: yup.string().required("Please select Location"),
  vendor_id: yup.string().required("Please select Vendor"),
  company_id: yup.string().required("Please select Company"),
  salary: yup
    .string()
    .required("Please enter salary")
    .matches(/^\d+$/, "Enter salary in Number only"),
});

const DesignationForm = ({
  toggleFormFillrter,
  manualFetchData,
  setGlobalFilter,
  isView,
  isEdit,
  previousData,
}) => {
  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);

  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleModalopen = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  const [vendorOption, setvendorOption] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  useEffect(() => {
    async function fetchData() {
      // You can await here
    //   const response = await axiosInstance.get(`web/vendor?page=1&limit=1000`);
      const response = await axiosInstance.get(`web/vendordata`);
      setvendorOption(response?.data?.data);
      // ...
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axiosInstance.get(
        `web/companydropdown`
      );
      setCompanyOption(response?.data?.data);
      // ...
    }
    fetchData();
  }, []);

  const selectedCompanyId = watch("company_id");
  useEffect(() => {
    if (selectedCompanyId) {
      // Fetch locations based on selected company_id if needed
      async function fetchLocations() {
        const response = await axiosInstance.get(
          `web/locationdata/${selectedCompanyId}`
        );
        if (!response?.data?.error) {
          setLocationOption(response?.data?.data);
        } else {
          setLocationOption([])
          showToast(response?.data?.message, "error");
        }
      }
      fetchLocations();
    }
  }, [selectedCompanyId]);

  const onSubmit = async (data) => {
    try {
      const dynamicEndPoint = isEdit
        ? `web/editdesignation/${previousData?.id}`
        : "web/adddesignation";
      const method = isEdit ? "post" : "post";

      const response = await axiosInstance({
        method: method,
        url: dynamicEndPoint,
        data: {
          ...data,
        },
      });

      if (response?.data?.statusCode !== 200) {
        showToast(response?.data?.message, "error");
        return false;
      }
      manualFetchData();
      toggleFormFillrter();
      showToast(response?.data?.message, "success");
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (isView || isEdit) {
      setValue("designation", previousData?.designation);
      setValue("company_id", previousData?.company_id);
      setValue("location_id", previousData?.location_id);
      setValue("vendor_id", previousData?.vendor_id);
      setValue("salary", previousData?.salary);
    }
  }, [
    isEdit,
    isView,
    previousData,
    // previousData?.vendor_id,
    setValue,
    companyOption,
    vendorOption,
    // locationOption
  ]);

  let result = checkObjectForNullUndefinedOrEmpty(watch());
  return (
    <>
      <section className="userlist-newuser content mobile">
        <div className="d-flex justify-content-end align-items-center mb-4 gap-3 flex-wrap">
          <h3 className="mb-0">
            <strong>
              {isEdit ? "Update" : isView ? "View" : "Add"} Designation Details
            </strong>
          </h3>
          <button
            onClick={() =>
              result && !isView ? handleModalopen() : toggleFormFillrter()
            }
            class="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
          >
            Back
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="user-details">
            <div className="card">
              <div className="row card-body  py-md-5 py-4 align-items-center">
                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="designation" className="form-label">
                      Designation<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="designation"
                      disabled={isView}
                      placeholder="Enter Designation"
                      {...register("designation")}
                    />
                    {errors?.designation && (
                      <p className="text-danger">
                        {errors?.designation?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="company_id" className="form-label">
                      Select Company
                      <span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="company_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={isView}
                          onChange={(e) => {
                            // Manually call the registered onChange method to ensure the value of company_id is updated
                            field.onChange(e);
                            setValue("location_id", "");
                          }}
                        >
                          <option value="" disabled>
                            Company
                          </option>
                          {companyOption?.map((ele) => (
                            <option value={ele?.id}>{ele?.company_name}</option>
                          ))}
                        </select>
                      )}
                    />

                    {errors?.company_id && (
                      <p className="text-danger">
                        {errors?.company_id?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="location_id" className="form-label">
                      Select Location<span className="text-danger">*</span>
                    </label>

                    <Controller
                      name="location_id"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="form-select py-2 w-100"
                          {...field}
                          defaultValue=""
                          disabled={isView}
                        >
                          <option value="" disabled>
                            Location
                          </option>
                          {locationOption?.map((ele) => (
                            <option value={ele?.id}>
                              {ele?.location_name}
                            </option>
                          ))}
                        </select>
                      )}
                    />

                    {errors?.location_id && (
                      <p className="text-danger">
                        {errors?.location_id?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 mb-3">
                  <div className="form-group">
                    <label htmlFor="vendor_id" className="form-label">
                      Select Vendor Name<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select py-2 w-100"
                      {...register("vendor_id")}
                      defaultValue=""
                      disabled={isView}
                    >
                      <option value="" disabled>
                        Vendor
                      </option>
                      {vendorOption?.map((ele) => (
                        <option value={ele?.id}>{ele?.vendor_name}</option>
                      ))}
                    </select>
                    {errors?.vendor_id && (
                      <p className="text-danger">
                        {errors?.vendor_id?.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="salary" className="form-label">
                      Salary<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="salary"
                      disabled={isView}
                      placeholder="Enter Salary"
                      {...register("salary")}
                    />
                    {errors.salary && (
                      <p className="text-danger">{errors.salary.message}</p>
                    )}
                  </div>
                </div>

                <div className="col-12 text-center mt-lg-5 mt-4 d-sm-inline-flex justify-content-sm-center d-grid gap-3">
                  <button
                    disabled={isView}
                    className="btn btn-lg btn-primary px-5"
                    type="submit"
                  >
                    {isEdit ? "UPDATE" : "SUBMIT"}
                  </button>
                  <button
                    disabled={isView || isEdit}
                    class="btn btn-lg btn-primary px-5 mx-xl-4"
                    type="button"
                    onClick={() => {
                      reset();
                      setLocationOption([]);
                    }}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <BackButtonModal
          isOpen={modal?.isOpen}
          handleClose={handleClose}
          toggleFormFillrter={toggleFormFillrter}
        />
      </section>
    </>
  );
};

export default DesignationForm;
