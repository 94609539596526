import React from "react";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import { NavLink, useLocation } from "react-router-dom";
import {
  Users,
  MapPin,
  Truck,
  Award,
  Flag,
  Trello,
  FileText,
  User,
  CheckSquare,
  UserCheck,
  Calendar,
  Lock,
  StopCircle,
  Sliders,
  AlertOctagon,
  AlertTriangle,
  ChevronDown,
  Clipboard,
  Smartphone,
} from "feather-icons-react";
import { useSelector } from "react-redux";
import { SelectSideBarToggles } from "../../store/slices/commonFunctionSlice";

const SidebarLayout = () => {
  let location = useLocation();
  let path = location.pathname;
  const pathArray = path.split("/");
  const sidebarToggle = useSelector(SelectSideBarToggles);
  const [roleRightsData, setRoleRightsData] = useState([]);

  const { setValue } = useForm({});

  const roleId = localStorage.getItem("role_id");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axiosInstance.get(
          `web/getrolerightsdata/${roleId}`
        );
        const rightsData = response?.data?.data || [];
        // alert(rightsData);
        console.log(rightsData);
        setRoleRightsData(rightsData);
        rightsData.forEach((right) => {
          setValue(right, true);
        });
      } catch (error) {
        showToast("Error fetching role rights data", "error");
      }
    }
    if (roleId) {
      fetchData();
    }
  }, [roleId, setValue]);

  return (
    <>
      <nav
        id="sidebar"
        className={`sidebar js-sidebar ${sidebarToggle ? "collapsed" : ""}`}
      >
        <div className="sidebar-content js-simplebar">
          <NavLink className="sidebar-brand" to="/dashboard">
            <img
              src="/assets/img/logo.png"
              alt=""
              className="rounded-circle w-25"
            />
            <span className="align-middle">Guard Admin</span>
          </NavLink>
          <ul className="sidebar-nav">
            {roleRightsData.includes("dashboard.view") ? (
              <li
                className={
                  pathArray[1] === "dashboard"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <NavLink className="sidebar-link" to="/dashboard">
                  <Sliders />
                  <span className="align-middle">Dashboard</span>
                </NavLink>
              </li>
            ) : null}
            <li
              className="sidebar-header"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              Master <ChevronDown />
            </li>
            {/* <li className="sidebar-header">Master</li> */}
            <div className="collapse" id="collapseExample">
              {roleRightsData.includes("user.view") ||
              roleRightsData.includes("user.add") ||
              roleRightsData.includes("user.edit") ||
              roleRightsData.includes("user.export") ? (
                <li
                  className={
                    pathArray[1] === "userlist"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/userlist">
                    <Users />
                    <span className="align-middle">User List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("rolerights.view") ||
              roleRightsData.includes("rolerights.add") ||
              roleRightsData.includes("rolerights.edit") ||
              roleRightsData.includes("rolerights.export") ? (
                <li
                  className={
                    pathArray[1] === "rolerights"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/rolerights">
                    <CheckSquare />
                    <span className="align-middle">Role Rights</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("company.view") ||
              roleRightsData.includes("company.add") ||
              roleRightsData.includes("company.edit") ||
              roleRightsData.includes("company.export") ? (
                <li
                  className={
                    pathArray[1] === "companymasterlist"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/companymasterlist">
                    <FileText />
                    <span className="align-middle">Company List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("location.view") ||
              roleRightsData.includes("location.add") ||
              roleRightsData.includes("location.edit") ||
              roleRightsData.includes("location.export") ? (
                <li
                  className={
                    pathArray[1] === "locationList"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/locationList">
                    <MapPin />
                    <span className="align-middle">Location List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("companylocation.view") ||
              roleRightsData.includes("companylocation.add") ||
              roleRightsData.includes("companylocation.edit") ||
              roleRightsData.includes("companylocation.export") ? (
                <li
                  className={
                    pathArray[1] === "companylist"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/companylist">
                    <MapPin />
                    <span className="align-middle">Company Location List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("vendor.view") ||
              roleRightsData.includes("vendor.add") ||
              roleRightsData.includes("vendor.edit") ||
              roleRightsData.includes("vendor.export") ? (
                <li
                  className={
                    pathArray[1] === "vendorlist"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/vendorlist">
                    <Truck />
                    <span className="align-middle">Vendor List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("shift.view") ||
              roleRightsData.includes("shift.add") ||
              roleRightsData.includes("shift.edit") ||
              roleRightsData.includes("shift.export") ? (
                <li
                  className={
                    pathArray[1] === "shiftlist"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/shiftlist">
                    <Trello />
                    <span className="align-middle">Shift List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("designation.view") ||
              roleRightsData.includes("designation.add") ||
              roleRightsData.includes("designation.edit") ||
              roleRightsData.includes("designation.export") ? (
                <li
                  className={
                    pathArray[1] === "designationlist"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/designationlist">
                    <Award />
                    <span className="align-middle">Designation List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("checkpoint.view") ||
              roleRightsData.includes("checkpoint.add") ||
              roleRightsData.includes("checkpoint.edit") ||
              roleRightsData.includes("checkpoint.export") ? (
                <li
                  className={
                    pathArray[1] === "checkpointlist"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/checkpointlist">
                    <Flag />
                    <span className="align-middle">Checkpoint List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("role.view") ||
              roleRightsData.includes("role.add") ||
              roleRightsData.includes("role.edit") ||
              roleRightsData.includes("role.export") ? (
                <li
                  className={
                    pathArray[1] === "roleList"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/roleList">
                    <User />
                    <span className="align-middle">Role List</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("appconfig.view") ||
              roleRightsData.includes("appconfig.add") ||
              roleRightsData.includes("appconfig.edit") ||
              roleRightsData.includes("appconfig.export") ? (
                <li
                  className={
                    pathArray[1] === "apkConfiguration"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/apkConfiguration">
                    <Smartphone />
                    <span className="align-middle">
                      App Version Configuration
                    </span>
                  </NavLink>
                </li>
              ) : null}
            </div>

            <li
              className="sidebar-header"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample2"
              aria-expanded="false"
              aria-controls="collapseExample2"
            >
              GPS Location <ChevronDown />
            </li>
            {/* <li className="sidebar-header">GPS Location</li> */}
            <div className="collapse" id="collapseExample2">
              {roleRightsData.includes("livelocation.view") ||
              roleRightsData.includes("livelocation.add") ||
              roleRightsData.includes("livelocation.edit") ||
              roleRightsData.includes("livelocation.export") ? (
                <li
                  className={
                    pathArray[1] === "liveLocation"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/liveLocation">
                    <MapPin />
                    <span className="align-middle">Live Location</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("tracklocation.view") ||
              roleRightsData.includes("tracklocation.add") ||
              roleRightsData.includes("tracklocation.edit") ||
              roleRightsData.includes("tracklocation.export") ? (
                <li
                  className={
                    pathArray[1] === "trackLocation"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/trackLocation">
                    <MapPin />
                    <span className="align-middle">Track Location</span>
                  </NavLink>
                </li>
              ) : null}
            </div>

            <li
              className="sidebar-header"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseExample3"
              aria-expanded="false"
              aria-controls="collapseExample3"
            >
              Reports <ChevronDown />
            </li>
            {/* <li className="sidebar-header">Reports</li> */}
            <div className="collapse" id="collapseExample3">
              {roleRightsData.includes("attendance.view") ||
              roleRightsData.includes("attendance.add") ||
              roleRightsData.includes("attendance.edit") ||
              roleRightsData.includes("attendance.export") ? (
                <li
                  className={
                    pathArray[1] === "attendanceReports"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/attendanceReports">
                    <Calendar />
                    <span className="align-middle">Attendance</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("firealertreport.view") ||
              roleRightsData.includes("firealertreport.add") ||
              roleRightsData.includes("firealertreport.edit") ||
              roleRightsData.includes("firealertreport.export") ? (
                <li
                  className={
                    pathArray[1] === "getfire"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/getfire">
                    <AlertOctagon />
                    <span className="align-middle">Fire Alert Report</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("gatelockreport.view") ||
              roleRightsData.includes("gatelockreport.add") ||
              roleRightsData.includes("gatelockreport.edit") ||
              roleRightsData.includes("gatelockreport.export") ? (
                <li
                  className={
                    pathArray[1] === "getLockReports"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/getLockReports">
                    <Lock />
                    <span className="align-middle">Gate Lock Report</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("trespassingreport.view") ||
              roleRightsData.includes("trespassingreport.add") ||
              roleRightsData.includes("trespassingreport.edit") ||
              roleRightsData.includes("trespassingreport.export") ? (
                <li
                  className={
                    pathArray[1] === "getTrespassing"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/getTrespassing">
                    <StopCircle />
                    <span className="align-middle">
                      Trespassing Alert Report
                    </span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("panicmodereport.view") ||
              roleRightsData.includes("panicmodereport.add") ||
              roleRightsData.includes("panicmodereport.edit") ||
              roleRightsData.includes("panicmodereport.export") ? (
                <li
                  className={
                    pathArray[1] === "getpanicmode"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/getpanicmode">
                    <AlertTriangle />
                    <span className="align-middle">Panic Mode Report</span>
                  </NavLink>
                </li>
              ) : null}
              {roleRightsData.includes("salaryreport.view") ||
              roleRightsData.includes("salaryreport.add") ||
              roleRightsData.includes("salaryreport.edit") ||
              roleRightsData.includes("salaryreport.export") ? (
                <li
                  className={
                    pathArray[1] === "getsalaryreport"
                      ? "sidebar-item active"
                      : "sidebar-item"
                  }
                >
                  <NavLink className="sidebar-link" to="/getsalaryreport">
                    <Clipboard />
                    <span className="align-middle">Salary Report</span>
                  </NavLink>
                </li>
              ) : null}
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
};
export default SidebarLayout;
