import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import { BackButtonModal } from "../../components/BackButtonModal";
import { checkObjectForNullUndefinedOrEmpty } from "../../utils/commonFunction";

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[a-zA-Z0-9 ]*$/, "Special Character is not allowed")
    .max(30, "Character length should be less than 30")
    .required("Please enter the Role Name").test("no-spaces-at-ends", "Please Enter Valid Input", (value) => {
      if (!value) return true; // Skip empty values (use required() to handle this)
      return value.trim() === value;
    }),
});

const RoleForm = ({
  toggleFormFillrter,
  manualFetchData,
  setGlobalFilter,
  isView,
  isEdit,
  previousData,
}) => {
  const [modal, setModal] = useState({
    isOpen: false,
  });

  const handleModalopen = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: true,
    }));
  };

  const handleClose = () => {
    setModal((prevData) => ({
      ...prevData,
      isOpen: false,
    }));
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    try {
      const dynamicEndPoint = isEdit
        ? `web/updaterole/${previousData?.id}`
        : "web/saverole";
      const method = isEdit ? "put" : "post";

      const response = await axiosInstance({
        method: method,
        url: dynamicEndPoint,
        data: {
          ...data,
        },
      });
      if (response?.data?.error) {
        showToast(response?.data?.message, "error");
        return false;
      }
      toggleFormFillrter();
      manualFetchData();
      showToast(response?.data?.message, "success");
    } catch (error) {
      showToast(error?.data?.message, "error");
    }
  };

  useEffect(() => {
    if (isView || isEdit) {
      setValue("name", previousData?.name);
      setValue("is_active", previousData?.is_active);
    }
  }, [isEdit, isView, previousData, setValue]);

  let result = checkObjectForNullUndefinedOrEmpty(watch());

  return (
    <>
      <section className="userlist-newuser content mobile">
        <div className="d-flex justify-content-end align-items-center mb-4 gap-3 flex-wrap">
          <h3 className="mb-0">
            <strong>{isEdit ? "Update" : isView ? "View" : "Add"} Role Details</strong>
          </h3>
          <button
            onClick={() =>
              result && !isView ? handleModalopen() : toggleFormFillrter()
            }
            className="btn btn-lg btn-primary px-md-5 px-4 addlistuser"
          >
            Back
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="user-details">
            <div className="card">
              <div className="row card-body justify-content-around py-md-5 py-4 align-items-center">
                <div className="col-lg-5 col-md-6">
                  <div className="form-group mb-3">
                    <label htmlFor="name" className="form-label">
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="name"
                      disabled={isView}
                      placeholder="Enter Name"
                      {...register("name")}
                    />
                    {errors.name && (
                      <p className="text-danger">{errors.name.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-5 col-md-6">
                  <div className="form-group mt-md-3 mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input mt-1"
                        type="checkbox"
                        id="isActive"
                        disabled={isView}
                        {...register("is_active")}
                        defaultChecked={!isView || !isEdit}
                      />
                      <label
                        className="form-check-label mb-0"
                        htmlFor="isActive"
                      >
                        IS ACTIVE
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-12 text-center mt-lg-5 mt-4 d-sm-inline-flex justify-content-sm-center d-grid gap-3">
                  <button
                    disabled={isView}
                    className="btn btn-lg btn-primary px-5"
                    type="submit"
                  >
                    {isEdit ? "UPDATE" : "SUBMIT"}
                  </button>
                  <button
                    disabled={isView || isEdit}
                    className="btn btn-lg btn-primary px-5 mx-xl-4"
                    type="button"
                    onClick={() => reset()}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <BackButtonModal
          isOpen={modal?.isOpen}
          handleClose={handleClose}
          toggleFormFillrter={toggleFormFillrter}
        />
      </section>
    </>
  );
};

export default RoleForm;
