/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  LogOut,
  User,
  Bell,
  AlertCircle,
  AlertTriangle,
  Home,
  UserPlus,
  Settings,
} from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../store/slices/LoginSlice";
import {
  SelectSideBarToggles,
  sideBarsToggleDispatch,
} from "../../store/slices/commonFunctionSlice";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import axios from "axios";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import NotAccessibleIcon from "@mui/icons-material/NotAccessible";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const HeaderLayout = ({ title }) => {
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState([]);
  const sidebarToggle = useSelector(SelectSideBarToggles);
  const handleSidebarToggle = () => {
    dispatch(sideBarsToggleDispatch(!sidebarToggle));
  };

  const currentPath = window.location.pathname;
  useEffect(() => {
    let source = axios.CancelToken.source();
    let isMounted = true;
    let interval;

    const fetchLocations = async () => {
      try {
        const response = await axiosInstance.get(`web/getnotificationreport`, {
          cancelToken: source.token,
        });
        if (isMounted && !response.data.error) {
          setNotificationData(response.data.data);
        } else if (isMounted) {
          setNotificationData([]);
          showToast(response.data.message, "error");
          clearInterval(interval); // Clear the interval if there's an error
        }
      } catch (error) {
        if (isMounted && !axios.isCancel(error)) {
          showToast(error.message, "error");
          clearInterval(interval); // Clear the interval if there's an error
        }
      }
    };

    interval = setInterval(() => {
      fetchLocations();
    }, 10000);

    if (currentPath) {
      fetchLocations();
    }

    // Cleanup function
    return () => {
      isMounted = false;
      source.cancel("Component unmounted or dependencies changed");
      clearInterval(interval);
    };
  }, [currentPath]);

  const [dashBoardData, setDashBoardData] = useState({});
  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axiosInstance.get(`web/dashboardData`);
      setDashBoardData(response?.data);
      // ...
    }
    fetchData();
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand navbar-light navbar-bg">
        <NavLink
          className="sidebar-toggle js-sidebar-toggle"
          onClick={() => {
            handleSidebarToggle();
          }}
        >
          <i className="hamburger align-self-center"></i>
        </NavLink>
        <p className="mb-0 fw-bold text-dark">{title}</p>
        <div className="navbar-collapse collapse">
          <ul className="navbar-nav navbar-align">
            <li className="nav-item dropdown">
              <NavLink
                className="nav-icon dropdown-toggle"
                to="/notification"
                id="alertsDropdown"
                data-bs-toggle="dropdown"
              >
                <div className="position-relative">
                  <Bell className="align-middle" />
                  <span className="indicator">
                    {notificationData?.Today?.length}
                  </span>
                </div>
              </NavLink>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0 scrollable-dropdown"
                aria-labelledby="alertsDropdown"
              >
                <div className="dropdown-menu-header">
                  {notificationData?.Today?.length} New Notifications
                </div>
                <div className="list-group">
                  <NavLink href="#" className="list-group-item">
                    <div className="text-dark">Today</div>
                    {notificationData.Today?.map((ele, index) => (
                      <div className="row g-0 align-items-center" key={ele?.id}>
                        <div className="col-2">
                          {ele?.alert_type === "fire" ? (
                            // <WarningAmberIcon
                            //   fontSize="small"
                            //   style={{ color: "#dc3545" }}
                            // />
                            <img
                              src="/assets/img/image_2024_06_27T06_32_29_806Z.png"
                              alt=""
                              height="25px"
                              width="auto"
                            />
                          ) : ele?.alert_type === "trespassing" ? (
                            // <NotAccessibleIcon
                            //   fontSize="small"
                            //   style={{ color: "#dc3545" }}
                            // />
                            <img
                              src="/assets/img/image_2024_06_27T06_34_03_181Z.png"
                              style={{
                                filter:
                                  "brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(5043%) hue-rotate(0deg) brightness(100%) contrast(107%)",
                                height: "25px",
                                width: "auto",
                              }}
                            />
                          ) : ele?.alert_type === "panic mode" ? (
                            <WarningAmberIcon
                              fontSize="small"
                              style={{ color: "#dc3545" }}
                            />
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div className="col-10">
                          <>
                            <div className="text-muted small mt-1">
                              <NavLink
                                to={
                                  ele?.alert_type === "fire"
                                    ? "/getfire"
                                    : ele?.alert_type === "trespassing"
                                    ? "/getTrespassing"
                                    : ele?.alert_type === "panic mode"
                                    ? "/getpanicmode"
                                    : "/dashboard"
                                }
                              >
                                Alert Message: {ele?.alert_message}
                              </NavLink>
                              <div>Alert Type: {ele?.alert_type}</div>
                            </div>
                            <div className="text-muted small mt-1">
                              {ele?.alert_created_time}
                            </div>
                          </>
                        </div>
                      </div>
                    ))}
                  </NavLink>

                  <NavLink href="#" className="list-group-item">
                    <div className="text-dark">Yesterday</div>
                    {notificationData.Yesterday?.map((ele, index) => (
                      <div className="row g-0 align-items-center" key={ele?.id}>
                        <div className="col-2">
                          {ele?.alert_type === "fire" ? (
                            // <LocalFireDepartmentIcon
                            //   fontSize="small"
                            //   style={{ color: "#dc3545" }}
                            // />
                            <img
                              src="/assets/img/image_2024_06_27T06_32_29_806Z.png"
                              alt=""
                              height="25px"
                              width="auto"
                            />
                          ) : ele?.alert_type === "trespassing" ? (
                            // <NotAccessibleIcon
                            //   fontSize="small"
                            //   style={{ color: "#dc3545" }}
                            // />
                            <img
                              src="/assets/img/image_2024_06_27T06_34_03_181Z.png"
                              style={{
                                filter:
                                  "brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(5043%) hue-rotate(0deg) brightness(100%) contrast(107%)",
                                height: "25px",
                                width: "auto",
                              }}
                            />
                          ) : ele?.alert_type === "panic mode" ? (
                            <WarningAmberIcon
                              fontSize="small"
                              style={{ color: "#dc3545" }}
                            />
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div className="col-10">
                          <>
                            <div className="text-muted small mt-1">
                              <NavLink
                                to={
                                  ele?.alert_type === "fire"
                                    ? "/getfire"
                                    : ele?.alert_type === "trespassing"
                                    ? "/getTrespassing"
                                    : ele?.alert_type === "panic mode"
                                    ? "/getpanicmode"
                                    : "/dashboard"
                                }
                              >
                                Alert Message: {ele?.alert_message}
                              </NavLink>
                              <div>Alert Type: {ele?.alert_type}</div>
                            </div>
                            <div className="text-muted small mt-1">
                              {ele?.alert_created_time}
                            </div>
                          </>
                        </div>
                      </div>
                    ))}
                  </NavLink>

                  <NavLink href="#" className="list-group-item">
                    <div className="text-dark">Two Days Ago</div>
                    {notificationData.TwoDaysAgo?.map((ele, index) => (
                      <div className="row g-0 align-items-center" key={ele?.id}>
                        <div className="col-2">
                          {ele?.alert_type === "fire" ? (
                            // <LocalFireDepartmentIcon
                            //   fontSize="small"
                            //   style={{ color: "#dc3545" }}
                            // />
                            <img
                              src="/assets/img/image_2024_06_27T06_32_29_806Z.png"
                              alt=""
                              height="25px"
                              width="auto"
                            />
                          ) : ele?.alert_type === "trespassing" ? (
                            // <NotAccessibleIcon
                            //   fontSize="small"
                            //   style={{ color: "#dc3545" }}
                            // />
                            <img
                              src="/assets/img/image_2024_06_27T06_34_03_181Z.png"
                              style={{
                                filter:
                                  "brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(5043%) hue-rotate(0deg) brightness(100%) contrast(107%)",
                                height: "25px",
                                width: "auto",
                              }}
                            />
                          ) : ele?.alert_type === "panic mode" ? (
                            <WarningAmberIcon
                              fontSize="small"
                              style={{ color: "#dc3545" }}
                            />
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div className="col-10">
                          <>
                            <div className="text-muted small mt-1">
                              <NavLink
                                to={
                                  ele?.alert_type === "fire"
                                    ? "/getfire"
                                    : ele?.alert_type === "trespassing"
                                    ? "/getTrespassing"
                                    : ele?.alert_type === "panic mode"
                                    ? "/getpanicmode"
                                    : "/dashboard"
                                }
                              >
                                Alert Message: {ele?.alert_message}
                              </NavLink>
                              <div>Alert Type: {ele?.alert_type}</div>
                            </div>
                            <div className="text-muted small mt-1">
                              {ele?.alert_created_time}
                            </div>
                          </>
                        </div>
                      </div>
                    ))}
                  </NavLink>

                  <NavLink href="#" className="list-group-item">
                    <div className="text-dark">One Week Ago</div>
                    {notificationData.OneWeekAgo?.map((ele, index) => (
                      <div className="row g-0 align-items-center" key={ele?.id}>
                        <div className="col-2">
                          {ele?.alert_type === "fire" ? (
                            // <LocalFireDepartmentIcon
                            //   fontSize="small"
                            //   style={{ color: "#dc3545" }}
                            // />
                            <img
                              src="/assets/img/image_2024_06_27T06_32_29_806Z.png"
                              alt=""
                              height="25px"
                              width="auto"
                            />
                          ) : ele?.alert_type === "trespassing" ? (
                            // <NotAccessibleIcon
                            //   fontSize="small"
                            //   style={{ color: "#dc3545" }}
                            // />
                            <img
                              src="/assets/img/image_2024_06_27T06_34_03_181Z.png"
                              style={{
                                filter:
                                  "brightness(0) saturate(100%) invert(22%) sepia(100%) saturate(5043%) hue-rotate(0deg) brightness(100%) contrast(107%)",
                                height: "25px",
                                width: "auto",
                              }}
                            />
                          ) : ele?.alert_type === "panic mode" ? (
                            <WarningAmberIcon
                              fontSize="small"
                              style={{ color: "#dc3545" }}
                            />
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div className="col-10">
                          <>
                            <div className="text-muted small mt-1">
                              <NavLink
                                to={
                                  ele?.alert_type === "fire"
                                    ? "/getfire"
                                    : ele?.alert_type === "trespassing"
                                    ? "/getTrespassing"
                                    : ele?.alert_type === "panic mode"
                                    ? "/getpanicmode"
                                    : "/dashboard"
                                }
                              >
                                Alert Message: {ele?.alert_message}
                              </NavLink>
                              <div>Alert Type: {ele?.alert_type}</div>
                            </div>
                            <div className="text-muted small mt-1">
                              {ele?.alert_created_time}
                            </div>
                          </>
                        </div>
                      </div>
                    ))}
                  </NavLink>
                </div>
                {/* <div className="dropdown-menu-footer">
                  <NavLink href="#" className="text-muted">
                    Show all notifications
                  </NavLink>
                </div> */}
              </div>
            </li>
            <li className="nav-item dropdown">
              <NavLink
                className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                href="#"
                data-bs-toggle="dropdown"
              >
                <User className="align-middle" />
              </NavLink>

              <NavLink
                className="nav-link dropdown-toggle d-none d-sm-inline-block"
                href="#"
                data-bs-toggle="dropdown"
              >
                {/* <img
                  src="/assets/img/avatars/avatar.jpg"
                  className="avatar img-fluid rounded me-1"
                  alt="Charles Hall"
                /> */}
                <User />
                <span className="text-dark">{dashBoardData?.username}</span>
              </NavLink>
              <div className="dropdown-menu dropdown-menu-end">
                {/* <NavLink className="dropdown-item" href="pages-profile.html">
                  <User className="align-middle me-1" />
                  My Profile
                </NavLink>
                <div className="dropdown-divider"></div> */}
                <NavLink
                  onClick={() => {
                    localStorage.clear();
                    dispatch(signOut());
                  }}
                  to="/"
                  className="dropdown-item"
                >
                  <LogOut className="align-middle me-1" />
                  Log out
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
export default HeaderLayout;
