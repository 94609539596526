import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Polyline,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import HeaderLayout from "../../layouts/admin/HeaderLayout";
import { Controller, useForm } from "react-hook-form";
import { axiosInstance } from "../../utils/commonInstance";
import { showToast } from "../../hooks/showToast";
import axios from "axios";

// Function to create custom icon for user markers
const createUserIcon = (color, text) => {
  const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="${color}" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
      <circle cx="12" cy="7" r="4"></circle>
      <path d="M12 14c-4.418 0-8 3.582-8 8v2h16v-2c0-4.418-3.582-8-8-8z"></path>
      <text x="12" y="20" text-anchor="middle" stroke="#ffffff" stroke-width="1px" dy=".3em">${text}</text>
    </svg>
  `;
  return new L.DivIcon({
    html: svgIcon,
    className: "",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
};

const createCheckpointIcon = (color) => {
  const svgIcon = `
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="${color}" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin">
        <path d="M21 10c0-5.523-4.477-10-10-10S1 4.477 1 10c0 7 10 13 10 13s10-6 10-13z"></path>
      </svg>
    `;
  return new L.DivIcon({
    html: svgIcon,
    className: "",
    iconSize: [48, 48],
    iconAnchor: [24, 48],
    popupAnchor: [0, -48],
  });
};

// Customize colors as needed

// Function to generate a color array
const generateColors = (numColors) => {
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const hue = ((i * 360) / numColors) % 360;
    colors.push(`hsl(${hue}, 100%, 50%)`);
  }
  return colors;
};

// Component to handle map view update
const MapViewUpdater = ({ lat, lng }) => {
  const map = useMap();

  useEffect(() => {
    if (lat && lng) {
      map.setView([lat, lng], 13);
    }
  }, [lat, lng, map]);

  return null;
};

const LiveLocation = ({}) => {
  const [companyOption, setCompanyOption] = useState([]);
  const [locationOption, setLocationOption] = useState([]);
  const [LiveLocationData, setLiveLocationData] = useState([]);
  const { control, watch, setValue } = useForm({
    mode: "all",
  });
  const colors = generateColors(LiveLocationData?.checkpoints?.length);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await axiosInstance.get(`web/companydropdown`);
      setCompanyOption(response?.data?.data);
      // ...
    }
    fetchData();
  }, []);

  const selectedCompanyId = watch("company_id");
  const selectedLocationId = watch("location_id");
  useEffect(() => {
    if (selectedCompanyId) {
      // Fetch locations based on selected company_id if needed
      async function fetchLocations() {
        const response = await axiosInstance.get(
          `web/locationdata/${selectedCompanyId}`
        );
        if (!response?.data?.error) {
          setLocationOption(response?.data?.data);
        } else {
          showToast(response?.data?.message, "error");
        }
      }
      fetchLocations();
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    let source = axios.CancelToken.source();
    let isMounted = true;
    let interval;

    const fetchLocations = async () => {
      try {
        const response = await axiosInstance.get(
          `web/livelocation?companyId=${selectedCompanyId}&locationId=${selectedLocationId}`,
          {
            cancelToken: source.token,
          }
        );
        if (isMounted && !response.data.error) {
          setLiveLocationData(response.data.data);
        } else if (isMounted) {
          setLiveLocationData([]);
          showToast(response.data.message, "error");
          clearInterval(interval); // Clear the interval if there's an error
        }
      } catch (error) {
        if (isMounted && !axios.isCancel(error)) {
          showToast(error.message, "error");
          clearInterval(interval); // Clear the interval if there's an error
        }
      }
    };

    // Fetch immediately when selectedLocationId changes or on component mount
    if (selectedLocationId) {
      fetchLocations();
    }

    interval = setInterval(() => {
      if (selectedLocationId) {
        fetchLocations();
      }
    }, 10000);

    // Cleanup function
    return () => {
      isMounted = false;
      source.cancel("Component unmounted or dependencies changed");
      clearInterval(interval);
    };
  }, [selectedCompanyId, selectedLocationId]);

  const firstCheckpoint = LiveLocationData?.checkpoints?.[0];

  return (
    <>
      <div className="main">
        <HeaderLayout title={"Live Location"} />
        <section className="user-listmain content">
          <div className="row">
            {/* <div className="col-xxl-10 col-xl-8 col--separator">
          <div className="mb-3 row row-cols-auto gy-2"> */}
            <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-6 col-12 position-relative">
              <div className="form-group">
                <label htmlFor="company_id">Select Company</label>

                <Controller
                  name="company_id"
                  control={control}
                  render={({ field }) => (
                    <select
                      className="form-select py-2 w-100"
                      {...field}
                      defaultValue=""
                      onChange={(e) => {
                        // Manually call the registered onChange method to ensure the value of company_id is updated
                        field.onChange(e);
                        setValue("location_id", "");
                      }}
                    >
                      <option value="" disabled>
                        Company
                      </option>
                      {companyOption?.map((ele) => (
                        <option value={ele?.id}>{ele?.company_name}</option>
                      ))}
                    </select>
                  )}
                />

                {/* {errors?.company_id && (
                      <p className="text-danger">
                        {errors?.company_id?.message}
                      </p>
                    )} */}
              </div>
            </div>

            <div className="col-xxl-3 col-xl-4 col-md-6 col-sm-6 col-12 position-relative">
              <div className="form-group">
                <label htmlFor="location_id" className="form-label">
                  Select Location
                </label>

                <Controller
                  name="location_id"
                  control={control}
                  render={({ field }) => (
                    <select
                      className="form-select py-2 w-100"
                      {...field}
                      defaultValue=""
                      onChange={(e) => {
                        // Manually call the registered onChange method to ensure the value of company_id is updated

                        field.onChange(e);

                        // handleGeocode(e?.target?.selectedOptions[0]?.label)
                      }}
                    >
                      <option value="" disabled>
                        Location
                      </option>
                      {locationOption?.map((ele) => (
                        <option value={ele?.id}>{ele?.location_name}</option>
                      ))}
                    </select>
                  )}
                />

                {/* {errors?.location_id && (
                      <p className="text-danger">
                        {errors?.location_id?.message}
                      </p>
                    )} */}
              </div>
            </div>
            {/* </div>
        </div> */}
            {/* <div className="col-xxl-2 col-xl-4 mb-3">
              <form>
                <div className="col-xl-12 col-sm-8 pe-xl-0 pe-sm-2">
                  <div className="form-group text-xl-center">
                    <label>Security Guard</label>
                  <input
                    type="text"
                    id="sname_scode"
                    name="sname_scode"
                    className="form-control py-2 fw-medium"
                    placeholder="Security Guard Code/Name"
                    required
                  />
                </div></div>
              </form>
            </div> */}

            {/* <div className="col-xxl-10 col-xl-8 col--separator pt-xl-4 pt-3"> */}
            <div className="col-12 pt-xl-4 pt-3">
              <h4 className="text-capitalize mb-2">
                <strong>Note:</strong> click on particular location and provide
                name
              </h4>

              <div id="map">
                <MapContainer
                  //   center={[51.505, -0.09]}
                  center={[28.471231802967917, 77.02304800613382]}
                  zoom={13}
                  scrollWheelZoom={true}
                  style={{ height: "60vh", width: "100%" }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {firstCheckpoint && (
                    <MapViewUpdater
                      lat={firstCheckpoint.lat}
                      lng={firstCheckpoint.lng}
                    />
                  )}
                  {LiveLocationData?.checkpoints?.map((checkpoint, index) => {
                    const color = colors[index % colors.length];
                    return (
                      <React.Fragment key={checkpoint.id}>
                        <Marker
                          position={[checkpoint.lat, checkpoint.lng]}
                          icon={createCheckpointIcon(color)}
                        >
                          <Popup>
                            <div>
                              <strong>{checkpoint.name}</strong>
                              <p>{checkpoint.details}</p>
                            </div>
                          </Popup>
                        </Marker>
                        {checkpoint.users.map((user, userIndex) => (
                          <Marker
                            key={user.id}
                            position={[user?.lat, user?.lng]}
                            icon={createUserIcon(color, userIndex + 1)}
                          >
                            <Popup>
                              <div>
                                <strong>{user.name}</strong>
                              </div>
                            </Popup>
                          </Marker>
                        ))}
                        {/* <Polyline positions={checkpoint?.pastLocations?.map(location => [location?.lat, location?.lng])} color={color} /> */}
                      </React.Fragment>
                    );
                  })}
                </MapContainer>
              </div>
            </div>
            {/* </div> */}
          </div>
        </section>
      </div>
    </>
  );
};

export default LiveLocation;
